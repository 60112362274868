import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

export type MenuItem = {id: string, text: string, className?: string, selected: boolean}

const StickyMenu = (props: { items: MenuItem[] }) => {
  const { items } = props
  const [open, setOpen] = useState(false)

  const classNames = [
    "menu", "menu-sticky", "menu-vertical"
  ]
  if (open) { classNames.push("menu-open") }

  return <div className={classNames.join(" ")}>
    <div className="menu-toggle" onClick={() => setOpen(!open)}>Table of Contents</div>
    {items.map(item => {
      const classNames = ['menu-item']
      if (item.selected) { classNames.push('selected')}
      if (item.className) { classNames.push(item.className)}

      return <Link className={classNames.join(' ')} key={item.id} to={`#${item.id}`}>{item.text}</Link>
    }
    )}
  </div>
}

function getOffset( el: HTMLElement | null ) {
    var _x = 0;
    var _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent as HTMLElement
    }
    return { top: _y, left: _x };
}

export function setupScrollSpy(refContent: React.RefObject<HTMLElement>,
    selector: string,
    dependencies: any[],
    setItems: ((items: MenuItem[]) => void)
  ) {
  const items: MenuItem[] = []

  const callback = () => {
    let selectedItem = { id: '', scroll: -99999 }
    const headings = refContent.current?.querySelectorAll(selector)
    const height = window.visualViewport.height
    headings?.forEach((item: Element) => {
      const offset = getOffset(item as HTMLElement)
      // In pixels from the bottom of the screen.
      // Less than zero means it has been scrolled into view
      const screenPos = offset.top - window.scrollY - height
      if (screenPos < 0) {
        if (selectedItem.scroll * -1 >= height && screenPos > selectedItem.scroll) {
          selectedItem = { id: item.id, scroll: screenPos }
          // The new item is closer to the bottom of the screen
        }
      }
    })

    const item = items.find(item => item.id === selectedItem.id)
    if (item) {
      for (let i of items) { i.selected = false }
      item.selected = true
      setItems([...items])
    }
  }

  useEffect(() => {
    const callbacks: (() => void)[] = []
    refContent.current?.querySelectorAll(selector).forEach((item: any, id) => {
      item.id = `menu-item-${id}`
      items.push({
        id: item.id,
        text: item.textContent,
        className: `menu-item-${item.tagName}`,
        selected: false
      })
      const observer = new IntersectionObserver(callback);
      observer.observe(item)
      callbacks.push(() => { observer.unobserve(item) })
    })

    setItems(items)
    return () => { for (const cb of callbacks) { cb() } }
  }, dependencies);

  return items
}

export default StickyMenu