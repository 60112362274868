import "./faqs.css"

import React from "react"
import Collapsible from 'react-collapsible'

import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { faMinus } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"

const FAQs = (props: { category?: string }) => {
  const { category } = props
  const { faqs } = useStaticQuery(graphql`
    query {
      faqs: allWpFaq {
        edges {
          node {
            title
            content
            locale { locale }
            categories {
              nodes {
                slug
              }
            }
          }
        }
      }
    }
  `)


  const { t, i18n } = useTranslation()
  const language = i18n.language
  let edges = faqs.edges
  edges = edges.filter((edge: any) => edge.node.locale.locale === language)
  if (category) {
    edges = edges.filter((edge: any) => edge.node.categories.nodes.map((cat: any) => cat.slug).includes(category))
  }

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": edges.map(({ node: { title, content }}: { node: { title: string, content: string } }) => ({
      "@type": "Question",
      "name": title,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": content
      }
    }))
  }

  return <div className="faqs">
    <h2 dangerouslySetInnerHTML={{__html:t("faqs") }} />
    <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(faqSchema) }} />
    {edges.map(({ node: { title, content } }: { node: { title: string, content: string } }, i: number) => 
      <Collapsible transitionTime={100} easing="ease" trigger={<>
        <div className="faq-title">
          {title}
          <div className="faq-icon">
            <FontAwesomeIcon icon={faPlus} />
            <FontAwesomeIcon icon={faMinus} />
          </div>
        </div>
      </>} key={i}>
        <div dangerouslySetInnerHTML={{__html: content }}></div>
      </Collapsible>
    )}
  </div>
}

export default FAQs