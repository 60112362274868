import "./exchanges.css"

import React, { useRef, useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Site, SiteCardSmall, SiteCardDetailed } from "@/components/site-card"

import Layout from "@/templates/layout"
import FAQs from "@/components/faqs"
import MediaObject from "@/components/media-object"
import StickyMenu, { MenuItem, setupScrollSpy } from "@/components/sticky-menu"
import { useTranslation } from "gatsby-plugin-react-i18next"
import AuthorBlock from "@/components/author-block"


const HeroUnit = ({ page }: { page: any }) => {
  const image = {
    data: page.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: page.featuredImage?.node?.alt || ``,
  }
  const media = image?.data && <GatsbyImage image={image.data} alt={image.alt} />

  return <>
    <MediaObject className="order-reverse" media={media}>
      <h1>{page.title}</h1>
      <AuthorBlock author={page.author.node} modified={page.modified} />
    </MediaObject>
  </>
}

const Index = (props: any) => {
  const { page, sites } = props.data
  if (!page) { return <></> }

  const { t, i18n } = useTranslation()
  const refContent = useRef<HTMLDivElement>(null)
  const [items, setItems] = useState<MenuItem[]>([])
  setupScrollSpy(refContent, 'h2, .site-card .site-link', [page.content], setItems)

  const content = page.content.split("!!!SITE_LIST!!!");
  const seo = {
    title: page.seoTitle,
    description: page.seoDescription,
    schema: page.seoSchema,
    url: "/exchanges",
  }

  return <Layout {...props} seo={seo} title={t("Crypto Exchanges")}>
    <HeroUnit page={page} />
    <hr />
  
    <div className="container container-columns">
      <div className="column-sidebar">
        <div className="sticky">
          <StickyMenu items={items} />
          <SiteListWidget className="hidden-mobile" sites={sites} />
        </div>
      </div>
      <div className="column-primary container-menu-items" ref={refContent}>
        {content.length >= 1 && <div className="page-content" dangerouslySetInnerHTML={{__html: content[0]}}></div>}
        <SiteListDetailed sites={sites} />
        {content.length >= 2 && <div className="page-content" dangerouslySetInnerHTML={{__html: content[1]}}></div>}
        {i18n.language === "en_GB" && <FAQs category="exchanges" />}
        {i18n.language === "fr_FR" && <FAQs />}
        <SiteListWidget className="hidden-desktop" sites={sites} />
      </div>
    </div>
  
  </Layout>
}

const SiteListWidget = (props: { className: string, sites: { nodes: Site[] } }) => {
  const { t } = useTranslation()
  const classNames = ['widget']
  if (props.className) { classNames.push(props.className) }

  return <div className={classNames.join(" ")}>
    <h3 dangerouslySetInnerHTML={{__html: t('best-crypto-exchanges')}} />
    <div className="site-list">
      {props.sites
        .nodes
        .sort((a, b) => a.menuOrder - b.menuOrder)
        .slice(0, 3)
        .map((site, i) => <SiteCardSmall key={i} site={site} />)}
    </div>
  </div>
}

const SiteListDetailed = (props: { sites: { nodes: Site[] } }) => {
  return <div className="site-list-detailed">
    <div className="site-list">
      {props.sites
        .nodes
        .sort((a, b) => a.menuOrder - b.menuOrder)
        .map((site, i) => <SiteCardDetailed key={i} site={site} />)}
    </div>
  </div>
}

export default Index

export const query = graphql`
  query ($language: String!) {
    page: wpPage(slug: { eq: "exchanges" }, locale: { locale: { eq: $language} }) {
      ...PageInformation
    }
    sites: allWpSite(filter: { locale: { locale: { eq: $language } } }) {
      nodes {
        ...SiteInformation
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
