import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql, Link } from "gatsby"
import React from "react"

const AuthorBlock = (props: { author: any, modified: string }) => {
  const { t, i18n } = useTranslation()
  const { avatar, name, slug } = props.author
  const { modified } = props
  const url = avatar.url.replace("data.madcrypto.com", "www.madcrypto.com")
  const months = {
    en: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", ],
    fr: [ "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre", ],
  }
  let dateParts = modified.split(" ")
  let month = parseInt(dateParts[0], 10) - 1
  const monthStr = months[i18n.language === "fr_FR" ? "fr" : "en"][month]
  const date = `${monthStr} ${dateParts[1]} ${dateParts[2]}`
  const reviewer = {
    name: "Clement Thibault",
    slug: "clement-thibault",
  }

  return <div className="author-info">
    <Link to={`/authors/${slug}/`}>
      <img className="author-avatar" src={url} width={avatar.size} height={avatar.size} />
    </Link>
    <div className="author">
      <div>
        {" "}
        <Link className="author-name" to={`/authors/${slug}/`}>
          {name}
        </Link>
      </div>
      <div className="last-updated">
        {t("last-updated-on", { date })}
      </div>
      {name !== reviewer && <div className="reviewed-by">
        <Link to={`/authors/${reviewer.slug}/`}>
          {t("Reviewed By", { author: reviewer.name })}
        </Link>
      </div>}
    </div>
  </div>
}

export default AuthorBlock